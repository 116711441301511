/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { hasPath, path } from 'ramda'
import Box from '@lib/ui/core/Box'
import useResponsive from '@hooks/use-responsive'
import Typography from '@lib/ui/core/Typography'
import Link from '@components/Link'
import MuiLink from '@lib/ui/core/Link'
import CreditCardList from '@components/Offer/CreditCard/List'
import flattenListItemPathIfExist from '@services/prismic/helpers/flatten-list-item-path'
import { linkResolver } from '@utils/link-resolver'
import Section from './Section'
import Content from './Content'
import CollapseContent from './CollapseContent'
import Button from '@lib/ui/core/Button'
import styled from 'styled-components'
import { addGaEvent } from '@utils/ga-event'

import { BodyText, Quote, ImageDefault, MediaEmbed, Ig, Related } from "@slices"

const StlyedSpan = styled(Typography)`
  border: 1px solid #ff0000;
  display: inline-block;
  width: 70px;
  line-height: 24px;
  color: #ff0000;
  margin-left: 1rem;
  text-align: center;
`


const Detail = ({ offer, quotaStatus,  mediaWidth = "40.5rem", handleClickOpen }) => {
  const { title, from, to, credit_cards, banks, offer_subcategory, photo_credit, description, terms_conditions, external_link, enquiry_hotline, body } = offer

  const isSelectedCard = credit_cards && Array.isArray(credit_cards) && credit_cards.length > 0
  const isSelectedBank = banks && Array.isArray(banks) && banks.length > 0
  const posts = []
  const offers = []

  useEffect(() => {
    addGaEvent({
      ga4Event: "page_view",
      eventParams: {
        page: `/${offer.offer_category?.name}/${offer.title}-${offer.prismicId}`,
        page_type: "offer",
        page_id: offer.prismicId,
        page_title: offer.title,
      },
    })
  }, [])

  return (
    <Box>
      { title && (
        <Box display="flex" mb={1}>
          <Typography variant="h1">
            {title} 
          { !quotaStatus &&(
            <StlyedSpan variant="body2" component="span">
              已換罄
            </StlyedSpan>
          )}
          </Typography>
        </Box>
      )}
      { hasPath(['name'], offer_subcategory) && (
        <Box
          color={offer_subcategory.image_color}
          my={{ xs: 1, sm: 2 }}
        >
          <Link
            to={linkResolver(offer_subcategory)}
            data-ele-name="offer-subcategory"
            color={offer_subcategory.image_color}
            fontWeight={800}
          >
            <Box component="span" fontSize="0.9rem" border={1} border={1} borderColor={offer_subcategory.image_color} borderRadius="3px" px={1} py={0.25}>
            {offer_subcategory.name}
            </Box>
          </Link>
        </Box>
      )}
      {isSelectedCard 
        && !isSelectedBank && (
        <Box display="flex" flexWrap="wrap" mb={0}>
          <CreditCardList
            cards={flattenListItemPathIfExist(
              ["credit_card"],
              credit_cards
            )}
            max={5}
            onlyXsHeight
          />
        </Box>
      )}
      {isSelectedCard 
        && isSelectedBank && (
        <Box display="flex" flexWrap="wrap" mb={0}>
          <CreditCardList
            cards={flattenListItemPathIfExist(
              ["credit_card"],
              credit_cards
            )}
            banks={banks}
            max={5}
            onlyXsHeight
          />
        </Box>
      )}
      {!isSelectedCard
        && isSelectedBank && (
          <Box display="flex" flexWrap="wrap" mb={0}>
            <CreditCardList
              banks={banks}
              max={5}
              onlyXsHeight
            />
          </Box>
      )}
      {!isSelectedCard
        && !isSelectedBank && (
          <Box display="flex" flexWrap="wrap" mb={0}>
            <CreditCardList
              max={5}
              onlyXsHeight
            />
          </Box>
      )}
      { photo_credit && (
        <Section>
          <Typography variant="body1">
            圖片來源：{photo_credit}
          </Typography>
        </Section>
      )}
      { from && to && (
        <Section title="推廣期">
          <Typography>{from}至{to}</Typography>
        </Section>
      )}
      { !from && to && (
        <Section title="推廣期">
          <Typography>至{to}</Typography>
        </Section>
      )}
      { from && !to && (
        <Section title="推廣期">
          <Typography>由{from}開始</Typography>
        </Section>
      )}
      { description && (
        <Section title="詳情">
          <Content dangerouslySetInnerHTML={{ __html: description.html }} />
        </Section>
      )}
      { terms_conditions && (
        <Section title="條款">
          <CollapseContent dangerouslySetInnerHTML={{ __html: terms_conditions.html }} />
        </Section>
      )}
      { external_link && hasPath(['url'], external_link) && (
        <Section title="網址">
          <MuiLink href={path(['url'], external_link)} target="_blank" color="text.primary" data-ele-name="offer-external-link">
            <Typography style={{ "word-wrap": "break-word" }}>{path(['url'], external_link)}</Typography>
          </MuiLink>
        </Section>
      )}
      {Array.isArray(enquiry_hotline) && enquiry_hotline.length > 0 && (
        <Section title="查詢電話">
          <Box>
            {enquiry_hotline.map(({ country_code, telephone, reminder }) => (
              <Box key={telephone} display="flex">
                <MuiLink href={`tel:+${country_code}${telephone}`} color="text.primary" data-ele-name="offer-telephone">
                  <Typography>{`+${country_code} ${telephone}`}</Typography>
                </MuiLink>
                {reminder && (
                  <>
                    <Box ml={1} />
                    <Typography>{`(${reminder})`}</Typography>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Section>
      )}
      {Array.isArray(body)
        && body.map((details) => (
          <Box key={details.id}>
            {details.slice_type === "section" ? (
              <React.Fragment key={details.id}>
                <Section>
                  <BodyText key={details.id} content={details} posts={posts} offers={offers} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "quote" ? (
              <React.Fragment key={details.id}>
                {/* <Box display="flex" mt={10} color="actionText.selected"> */}
                <Section>
                  <Quote content={details} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "images" ? (
              <React.Fragment key={details.id}>
                {/* <Section mt={10} maxWidth="40.5rem"> */}
                <Section maxWidth={mediaWidth}>
                  <ImageDefault content={details} handleClickOpen={handleClickOpen} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "embed_media" ? (
              <React.Fragment key={details.id}>
                {/* <Box mt={10} maxWidth="40.5rem"> */}
                <Section maxWidth={mediaWidth}>
                  <MediaEmbed content={details} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "instagram_post" ? (
              <React.Fragment key={details.id}>
                {/* <Box mt={10} maxWidth="40.5rem"> */}
                <Section>
                  <Ig content={details} />
                </Section>
              </React.Fragment>
            ) : details.slice_type === "related_post" ? (
              hasPath(["primary", "related_post", "title"], details)
              && <Related content={details} key={details.id} />
            ) : ""}
          </Box>
        ))}
    </Box>
  )
}

export default Detail

import React, { useState, useEffect } from 'react'
import Button from '@lib/ui/core/Button'
import Box from '@lib/ui/core/Box'
import InputBase from '@lib/ui/core/InputBase'
import Modal from '@lib/ui/core/Modal'
import Typography from '@lib/ui/core/Typography'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Link from '@lib/ui/core/Link'
import urljoin from 'url-join'
import ReactDOM from 'react-dom';
import {QRCodeSVG} from 'qrcode.react';

import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'
import AppStore from '@assets/images/appStore.png'
import GooglePlay from '@assets/images/GooglePlay.png'

const AppPopup = ({ open, url = '', onClose, onSubmit, children }) => {
  const [value, setValue] = useState('')

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 640)
  }, [deviceWidth])

  const handleChange = (ev) => {
    setValue(ev.target.value)
  }

  const updateInfo = () => {
    onSubmit(value)
  }

  const handleClose = () => {
    setValue('')
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box pt={2.5}>
          <Box mb={1}>
            <Typography variant="h2" align="center">
              想要兌換此優惠？
            </Typography>
          </Box>
          <Box mb={2.5} textAlign="center">
          {isMobile ? (
            <Typography style={{ color: '#38CAB0' }}>
              立即下載 JetsoBee 手機 App
            </Typography>
          ) : (
          <Typography style={{ color: '#38CAB0' }}>
            掃描二維碼下載 JetsoBee 手機 App
          </Typography>
          )}
          </Box>
          <Box mb={2.5} textAlign="center">
          {isMobile ? (
            <Box width="5.5rem" m="auto" boxShadow={3}>
              <SvgIcon component={Logo} fontSize="4.5rem" />
            </Box>
          ) : (
            <QRCodeSVG value={url} size="256" />
          )}
          </Box>
          <Box display="flex" justifyContent="center" mb={4}>
            <Link color="initial" href="https://apps.apple.com/hk/app/jetsobee著數蜂子-個人化信用卡優惠平台/id1499098955?l">
              <Box mr={1} component="ButtonBase" height="100%" width={{ xs: "7rem", sm: "8.25rem" }}>
                <img height="39px" src={AppStore} alt="AppStore" />
              </Box>
            </Link>
            <Link color="initial" href="https://play.google.com/store/apps/details?id=com.jetso.smartspending">
              <Box component="ButtonBase" height="100%" width={{ xs: "8.5rem", sm: "10rem" }}>
                <img height="39px" src={GooglePlay} alt="AppStore" />
              </Box>
            </Link>
          </Box>
          {isMobile &&(
          <Link color="initial" href={url}>
            <Button fullWidth={({ xs: true, sm: false })} minWidth={{ xs: 0, sm: 360 }}>
              <Typography component="span">立即下載</Typography>
            </Button>
          </Link>
          )}
        </Box>
    </Modal>
  )
}

export default AppPopup

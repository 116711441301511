import React from 'react'
import { hasPath } from 'ramda'
import { ReactComponent as CloseIcon } from '@assets/icons/close-small.svg'
import Box from '@lib/ui/core/Box'
import Dialog from '@lib/ui/core/DialogWithCloseButton'
import Divider from '@lib/ui/core/Divider'
import Typography from '@lib/ui/core/Typography'
import BranchSummary from './BranchSummary'

const BranchListModal = ({ branches, merchant, open, onClose }) => (
  <Dialog title="分店資料" open={open} onClose={onClose} closeIcon={CloseIcon}>
    { Array.isArray(branches) && branches.length > 0 && branches.map((branch, i) => (
      <Box key={branch.uid} data-ele-section="merchant-branches-modal">
        { hasPath(['name'], branch) && (
          <Box mb={2}>
            <Typography variant="h1" component="h3">{branch.name}</Typography>
          </Box>
        )}
        <BranchSummary branch={branch} merchant={merchant} />
        { i < (branches.length - 1) && (
          <Divider my={2.5} />
        )}
      </Box>
    ))}
  </Dialog>
)

export default BranchListModal

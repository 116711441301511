import React from 'react'
import styled from 'styled-components'
import Typography from '@lib/ui/core/Typography'

const StyledContent = styled.div`
  p {
    margin: 1em
  }
`

// eslint-disable-next-line prefer-arrow-callback
const Content = React.forwardRef(function Content(props, ref) {
  return <Typography component={StyledContent} ref={ref} {...props} />
})

export default Content

import React from 'react'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'

const Section = ({ children, title, mb = 2.5 }) => (
  <Box mb={mb}>
    {title && (
      <Box mb={{ xs: 0.25, sm: 0.5 }}>
        <Typography variant="subtitle1" color="textSecondary" component="h2">{title}</Typography>
      </Box>
    )}
    <Box>
      {children}
    </Box>
  </Box>
)

export default Section

/* eslint-disable camelcase */
import React from 'react'
import { hasPath, path } from 'ramda'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import { ReactComponent as PhoneIcon } from '@assets/icons/phone.svg'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Link from '@lib/ui/core/Link'
import SummaryItem from './SummaryItem'


const BranchSummary = ({ branch: { telephones, address1, address2, neighborhood }, merchant }) => {
  const validNeighborhood = neighborhood && hasPath(['name'], neighborhood)
  const region = validNeighborhood && hasPath(['region'], neighborhood) ? path(['region'], neighborhood) : undefined
  const validRegion = region && hasPath(['name'], region)

  return (
    <>
      {address1 && (
        <SummaryItem icon={LocationIcon}>
          <Box>
            <Box color="actionText.active">
              <Link href={`https://www.google.com/maps/search/${merchant}+${address1}${address2 ? `+${address2}` : ''}${validNeighborhood ? `+${neighborhood.name}` : ''}`} target="_blank" data-ele-name="branch-map">地圖</Link>
            </Box>
            <Box>
              <Typography>{address1}</Typography>
            </Box>
            {address2 && (
              <Box>
                <Typography>{address2}</Typography>
              </Box>
            )}
            {validNeighborhood && (
              <Box display="flex">
                <Typography>{neighborhood.name}</Typography>
                {validRegion && (
                  <Typography>{`, ${region.name}`}</Typography>
                )}
              </Box>
            )}
          </Box>
        </SummaryItem>
      )}
      {Array.isArray(telephones) && telephones.length > 0 && (
        <SummaryItem icon={PhoneIcon}>
          <Box>
            {telephones.map(({ country_code, telephone, reminder }) => (
              <Box key={telephone} display="flex">
                <Link href={`tel:+${country_code}${telephone}`} color="text.primary" data-ele-name="branch-telephone">
                  <Typography>{`+${country_code} ${telephone}`}</Typography>
                </Link>
                {reminder && (
                  <>
                    <Box ml={1} />
                    <Typography>{`(${reminder})`}</Typography>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </SummaryItem>
      )}
    </>
  )
}

export default BranchSummary


import React from 'react'
import Box from '@lib/ui/core/Box'
import SvgIcon from '@lib/ui/core/SvgIcon'

const SummaryItem = ({ icon: IconComponent, alignItems = "top", children }) => (
  <Box display="flex" alignItems={alignItems} mb={{ xs: 1, sm: 2 }}>
    <Box mr={{ xs: 0.625, sm: 1.5 }}>
      <Box display="flex" alignItems="center" height={{ xs: "calc(0.875rem * 1.5)", sm: "calc(1rem * 1.5)" }}>
        <SvgIcon component={IconComponent} fontSize={{ xs: "1.25rem", sm: "1.375rem" }} />
      </Box>
    </Box>
    { children }
  </Box>
)

export default SummaryItem

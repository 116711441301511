/* eslint-disable camelcase */
import React, { useState } from 'react'
import { hasPath, useWith, path, equals, filter, identity } from 'ramda'
import { ReactComponent as InternetIcon } from '@assets/icons/internet.svg'
import useResponsive from '@hooks/use-responsive'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Link from '@lib/ui/core/Link'
import Button from '@lib/ui/core/Button'
import SummaryItem from './SummaryItem'
import BranchSummary from './BranchSummary'
import BranchListModal from './BranchListModal'

const getId = useWith(path, [identity, identity])(['merchant', 'prismicId'])
const matchMerchantId = useWith(equals, [identity, getId])

const MerchantSummaryList = ({ merchants, selectedBranches, allBranches }) => {
  const [open, setOpen] = useState(false)
  const r = useResponsive()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  // console.log(merchants)
  // console.log(selectedBranches)
  // console.log(allBranches)

  if (!(Array.isArray(merchants) && merchants.length > 0)) {
    return null
  }

  return (
    <>
      {merchants.map(({ uid, prismicId, name, homepage_url, representative_branch }) => {
        const matchedSelectedBranches = Array.isArray(selectedBranches) && selectedBranches.length > 0 ? filter(matchMerchantId(prismicId), selectedBranches) : undefined
        const firstBranch = Array.isArray(matchedSelectedBranches) && matchedSelectedBranches.length > 0 ? matchedSelectedBranches[0] : representative_branch
        const branches = Array.isArray(matchedSelectedBranches) && matchedSelectedBranches.length > 0 ? matchedSelectedBranches : filter(matchMerchantId(prismicId), allBranches)

        return (
          <Box key={uid} mb={2.5}>
            <Box mb={2}>
              <Typography variant="h1" component="h3">
                {name}
              </Typography>
            </Box>
            <Box mb={2.5}>
              {firstBranch && (
                <BranchSummary branch={firstBranch} merchant={name} />
              )}
              {hasPath(['url'], homepage_url) && (
                <SummaryItem icon={InternetIcon}>
                  <Link href={path(['url'], homepage_url)} target="_blank" color="text.primary" data-ele-name="merchant-homepage">
                    <Typography>{path(['url'], homepage_url)}</Typography>
                  </Link>
                </SummaryItem>
              )}
            </Box>
            {Array.isArray(branches) && branches.length > 0 && (
              <>
                <Box textAlign='center'>
                  <Button fullWidth={r({ xs: true, sm: false })} minWidth={{ xs: 0, sm: 360 }} onClick={handleClickOpen} data-ele-name="btn-more-branches">
                    <Typography component="span">查看所有分店資料</Typography>
                  </Button>
                </Box>
                {/* <BranchListModal branches={branches} merchant={name} open={open} onClose={handleClose} /> */}
                <BranchListModal branches={branches} merchant={name} open={open} onClose={handleClose} />
              </>
            )}
          </Box>
        )
      })}
    </>
  )
}

export default MerchantSummaryList

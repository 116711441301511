/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { map } from 'ramda'
import urljoin from 'url-join'
import { useStoreActions } from 'easy-peasy';
import { parseOffer, parseMerchantBranch } from '@services/prismic/helpers'
import { linkResolver } from '@utils/link-resolver'
import flattenListItemPathIfExist from '@services/prismic/helpers/flatten-list-item-path'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import Box from '@lib/ui/core/Box'
import Hidden from '@lib/ui/core/Hidden'
import SEO from '@components/SEO'
import AppLink from '@components/AppLink'
import CtaBanner from '@components/CtaBanner'
import JsonLd from '@components/JsonLd'
import FilledCarousel from '@components/Carousel/Filled'
import Divider from '@components/Divider'
import TagList from '@components/Tag/List'
import OfferDetail from '@components/Offer/Detail'
import OfferList from '@components/Offer/List'
import MerchantSummaryList from '@components/Merchant/SummaryList'
import Share from '@components/Share'
import EmailPopup from '@components/EmailPopup'
import useEmailPopup from '@hooks/use-view-scroll'
import articleSchema from '@utils/json-ld/article'
import { useAllCreditCard } from '@hooks/use-all-credit-card'
import useResponsive from '@hooks/use-responsive'
import Typography from '@lib/ui/core/Typography'
import AppPopup from '@components/AppPopup'
import axios from 'axios'
import Button from '@lib/ui/core/Button'
import styled from 'styled-components'
import SearchInput from "@components/Search/SearchInput"
import { addGaEvent } from '@utils/ga-event'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import '@assets/style/lightbox.css'

const ButtonShadow = styled(Button)`
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 25%);
  &&& {
    &.Mui-disabled {
      color: #000000;
      background-color: #C4C4C4;
    }
  }
`


const OfferPage = ({ data, location }) => {
  const doc = data && data.prismicOffer
  if (!doc) return null
  const offer = parseOffer(doc)

  const { featured_images: featuredImages, title, tagged, merchants, merchant_branches } = offer
  const featuredImagesList = featuredImages && map((featuredImage) => {
    if (!featuredImage.image.fluid) return null
    return featuredImage.image
  }, featuredImages)

  const { meta_title, meta_keywords, meta_description, og_image, first_publication_date, last_publication_date } = offer

  const relatedOffersDoc = data && data.relatedOffers && data.relatedOffers.nodes
  const relatedOffers = parseOffer(relatedOffersDoc)
  const hasRelatedOffers = Array.isArray(relatedOffers) && relatedOffers.length > 0

  const allMerchantBranchesDoc = data && data.allMerchantBranches && data.allMerchantBranches.nodes
  const allMerchantBranches = parseMerchantBranch(allMerchantBranchesDoc)

  const ctabannerDoc = data && data.allPrismicCtaBanner && data.allPrismicCtaBanner.nodes
  const { siteMetadata } = data.site
  const { publisherLogo } = data


  const [anchorRef, inView] = useEmailPopup()

  // Load Credit Cards at index page
  const updateCreditCards = useStoreActions(({ allCreditCards }) => allCreditCards.update);
  const allCreditCardData = useAllCreditCard()

  const [quotaData, setQuotaData] = useState([])
  const [quotaStatus, setQuotaStatus] = useState(2)

  const url = urljoin(process.env.GATSBY_API_URL, 'offers/', offer.prismicId)
  const link = urljoin(process.env.GATSBY_BRANCH_LINK_URL, offer.prismicId)

  const handleTagClick = tag => {
    addGaEvent({
      ga4Event: "tag_click",
      eventParams: {
        item_name: tag.name,
        content_type: "offer",
        item_type: "offer_tag",
      },
    })
  }

  useEffect(() => {
    updateCreditCards(allCreditCardData)
  }, [])

  const [ctaBannerData, setCtaBannerData] = useState([])
  useEffect(() => {
    setCtaBannerData(ctabannerDoc.sort(()=>Math.random() - 0.5))
  }, [])

  useEffect(() => {
    const fetchQuotaData = async () => {
      const response = await axios.get(url)
      const offerData = response.data.data
      if (offerData.is_exclusive) {
        setQuotaData(`${offerData.quota.left_number}/${offerData.quota.total_number}`)
        if (offerData.quota.left_number === 0) {
          setQuotaStatus(false)
        } else {
          setQuotaStatus(true)
          if (offerData.quota.total_number === null) {
            setQuotaStatus(3)
            setQuotaData(null)
          }
        }
      }
      else {
        setQuotaData(null)
        setQuotaStatus(2)
      }
    }
    fetchQuotaData()
  }, [location])

  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState(false)
  const [imagesrc, setImagesrc] = useState()
  const r = useResponsive()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handlePopupOpen  = (link) => () => {
    setPopup(true)
    setImagesrc(link)
  }
  const handlePopupClose = () => {
    setPopup(false)
  }

  return (
    <Layout type="offer">
      <SEO
        title={meta_title || title}
        description={meta_description}
        keywords={meta_keywords}
        image={og_image && og_image.url}
      />
      <AppLink path={location.pathname} />
      <JsonLd>
        {articleSchema({
          url: urljoin(siteMetadata.siteUrl, linkResolver(offer)),
          headline: title,
          image: featuredImagesList.map((image) => image.url),
          datePublished: first_publication_date,
          dateModified: last_publication_date,
          author: siteMetadata.title,
          publisher: {
            name: siteMetadata.title,
            logo: urljoin(siteMetadata.siteUrl, publisherLogo.childImageSharp.original.src),
          },
        })}
      </JsonLd>
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <Box mb={2.5}>
        <FilledCarousel images={featuredImagesList} aspectRatio="1.78" quota={quotaData} quotaStatus={quotaStatus} />
        {offer.offer_subcategory && offer.offer_subcategory.image && offer.offer_subcategory.image.url &&
        (
        <Box position="relative" maxWidth={800} mx="auto">
          <Box pt={1} position="absolute" bgcolor={offer.offer_subcategory.image_color} borderRadius="0px 1rem 0 0" width="32px" minHeight="32px" textAlign="center" bottom={0} left={0}>
            <img width="16px" height="16px" src={offer.offer_subcategory.image.url} alt={offer.offer_subcategory.name} />
          </Box>
        </Box>
        )}
        <Divider />
      </Box>
      <Container>
        <Box px={{ md: 20 }}>
          { tagged && (
            <Box mb={{ xs: 2.5, sm: 4 }} data-ele-section="offer-tag-list">
              <TagList tags={tagged} handleTagClick={handleTagClick} />
            </Box>
          )}
          <Box mb={4} data-ele-section="offer-detail">
            <OfferDetail offer={offer} quotaStatus={quotaStatus} handleClickOpen={handlePopupOpen} />
          </Box>
          { Array.isArray(merchants) && merchants.length > 0 && (
            <Box mb={4} data-ele-section="offer-detail-merchant">
              <MerchantSummaryList merchants={flattenListItemPathIfExist(['merchant'], merchants)} selectedBranches={flattenListItemPathIfExist(['merchant_branch'], merchant_branches)} allBranches={allMerchantBranches} />
            </Box>
          )}
          <Box mb={4}>
            <Share url={location && location.origin ? urljoin(location.origin, linkResolver(offer)) : linkResolver(offer)} />
          </Box>
        </Box>
      </Container>
      { ctaBannerData.length > 0 && (
        <CtaBanner ctabanner={ctaBannerData[0].data} eventCategory="offer_detail_cta_banner" eventLabel={linkResolver(offer)} />
      )}

      <Box bgcolor={{ sm: "grey.100" }} pt={{ sm: 1 }}>
        {hasRelatedOffers && (
          <Box pb={4}>
            <Hidden smUp>
              <Box mb={2}>
                <Divider />
              </Box>
            </Hidden>
            <Container data-ele-section="related-offer-list" ref={anchorRef}>
              {/* <OfferList title="為您推薦" offers={hasRelatedOffers ? relatedOffers : latestOffers} /> */}
              <OfferList title="為您推薦" offers={relatedOffers} />
            </Container>
            <EmailPopup dataEleSection="offer-subscription-popup" inView={inView} />
          </Box>
        )}
      </Box>
      { (quotaStatus !== false && quotaStatus !== 2) && (
      <Box textAlign="center" position="sticky" bottom={0} py={1} bgcolor="#fff" display="flex" justifyContent="center" boxShadow="0 -2px 4px 0 rgb(0 0 0 / 25%)">
        <ButtonShadow fullWidth={r({ xs: true, sm: false })} width={300} onClick={handleClickOpen} data-ele-name="btn-quota">
          <Typography component="span">立即使用</Typography>
        </ButtonShadow>
      </Box>
      )}
      { !quotaStatus && (
      <Box textAlign="center" position="sticky" bottom={0} py={1} bgcolor="#fff" display="flex" justifyContent="center" boxShadow="0 -2px 4px 0 rgb(0 0 0 / 25%)">
        <ButtonShadow fullWidth={r({ xs: true, sm: false })} width={300} disabled data-ele-name="btn-no-quota">
          <Typography component="span">已換罄</Typography>
        </ButtonShadow>
      </Box>
      )}
      <AppPopup open={open} url={link} onClose={handleClose} />
      {popup && (
      <Lightbox
      mainSrc={imagesrc}
        onCloseRequest={() => handlePopupClose()}
      />)}
    </Layout>
  )
}

export default OfferPage

export const query = graphql`
  query OfferDetailQuery($id: ID!, $categoryId: ID, $merchantIds: [ID], $today: Date) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    publisherLogo: file(relativePath: { eq: "publisher-logo.png" }) {
      childImageSharp {
        original {
          src
        }
      }
    }
    allPrismicCtaBanner(
      sort: {
        fields: [
          last_publication_date
        ]
        order: [
          DESC,
          DESC
        ]
      },
      filter: {
        data: {
          from: {
            lte: $today
          },
          to: {
            gte: $today
          },
          page: {
            eq: "offer_detail"
          }
        }
      }
    ) {
      totalCount
      nodes {
        data {
          image {
            url
          }
          description{
            text
          }
          button_text
          redirect_url {
            link_type
            target
            url
            document {
              ... on PrismicOffer {
                id
                uid
                type
                url
                data {
                  offer_category {
                    uid
                  }
                  offer_subcategory {
                    uid
                  }
                }
              }
              ... on PrismicPost {
                id
                uid
                type
                url
                data {
                  post_category {
                    uid
                  }
                  post_subcategory {
                    uid
                  }
                }
              }
            }
          }
          from(formatString: "LL", locale: "zh-tw")
          to(formatString: "LL", locale: "zh-tw")
          title
        }
      }
    }
    prismicOffer(prismicId: { eq: $id }) {
      data {
        offer_category {
          document {
            ... on PrismicOfferCategory {
              data {
                name
              }
              type
              uid
            }
          }
        }
        offer_subcategory {
          document {
            ... on PrismicOfferSubcategory {
              uid
              type
              data {
                name
                offer_category {
                  uid
                  type
                }
                image {
                  url
                }
                image_color
              }
            }
          }
        }
        featured_images {
          image {
            fluid(maxWidth: 1084) {
              ...GatsbyPrismicImageFluid_noBase64
            }
            alt
            url
          }
        }
        merchant_branches {
          merchant_branch {
            document {
              ... on PrismicMerchantBranch {
                uid
                prismicId
                data {
                  address1
                  address2
                  name
                  neighborhood {
                    document {
                      ... on PrismicNeighborhood {
                        uid
                        prismicId
                        data {
                          name
                          district {
                            document {
                              ... on PrismicDistrict {
                                data {
                                  name
                                }
                                prismicId
                                uid
                              }
                            }
                          }
                          region {
                            document {
                              ... on PrismicRegion {
                                uid
                                prismicId
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  telephones {
                    country_code
                    reminder
                    telephone
                  }
                  merchant {
                    document {
                      ... on PrismicMerchant {
                        uid
                        prismicId
                      }
                    }
                  }
                }
              }
            }
          }
        }
        merchants {
          merchant {
            document {
              ... on PrismicMerchant {
                uid
                prismicId
                data {
                  name
                  representative_branch {
                    document {
                      ... on PrismicMerchantBranch {
                        uid
                        prismicId
                        data {
                          address1
                          address2
                          name
                          neighborhood {
                            document {
                              ... on PrismicNeighborhood {
                                uid
                                prismicId
                                data {
                                  name
                                  district {
                                    document {
                                      ... on PrismicDistrict {
                                        data {
                                          name
                                        }
                                        prismicId
                                        uid
                                      }
                                    }
                                  }
                                  region {
                                    document {
                                      ... on PrismicRegion {
                                        data {
                                          name
                                        }
                                        uid
                                        prismicId
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          telephones {
                            country_code
                            reminder
                            telephone
                          }
                        }
                      }
                    }
                  }
                  homepage_url {
                    isBroken
                    lang
                    link_type
                    target
                    url
                  }
                }
              }
            }
          }
        }
        banks {
          bank {
            document {
              ... on PrismicBank {
                uid
                prismicId
                data {
                  name
                }
              }
            }
          }
        }
        credit_cards {
          credit_card {
            document {
              ... on PrismicCreditCard {
                uid
                data {
                  name
                  display_on_offer_page
                  display
                  bank {
                    id
                  }
                  card_image {
                    alt
                    url
                    dimensions {
                      width
                      height
                    }
                    fixed(height: 42){
                      ...GatsbyPrismicImageFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        short_description {
          text
          html
        }
        description {
          text
          html
        }
        terms_conditions {
          text
          html
        }
        tagged {
          tag {
            type
            uid
            document {
              ... on PrismicTag {
                id
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ...on PrismicOfferBodySection {
            id
            primary {
              heading_url {
                url
              }
              heading {
                text
                html
                raw
              }
              content {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicOfferBodyQuote {
          id
          primary {
            content {
                html
                raw
              }
            }
            slice_type
          }
          ... on PrismicOfferBodyImages {
            id
            items {
              caption
              image {
                fluid(maxWidth: 648) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
              }
              link {
                type
                url
                target
                document {
                  ... on PrismicOffer {
                    id
                    uid
                    url
                  }
                  ... on PrismicPost {
                    id
                    uid
                    url
                  }
                  ... on PrismicCreditCard {
                    id
                    uid
                    url
                  }
                }
              }
              allow_enlarge
            }
            slice_type
          }
          ... on PrismicOfferBodyInstagramPost {
            id
            primary {
              instagram_embed {
                embed_url
                html
              }
            }
            slice_type
          }
          ... on PrismicOfferBodyEmbedMedia {
            id
            primary {
              embed {
                title
                embed_url
                html
                type
              }
            }
            slice_type
          }
          ... on PrismicOfferBodyRelatedPost {
            id
            slice_type
            primary {
              related_post {
                document {
                  ...on PrismicPost {
                    uid
                    prismicId
                    data {
                    post_subcategory {
                      uid
                    }
                    title
                    }
                    type
                  }
                }
              }
            }
          }
        }
        photo_credit
        title
        from(formatString: "LL", locale: "zh-tw")
        to(formatString: "LL", locale: "zh-tw")
        external_link {
          isBroken
          lang
          link_type
          target
          url
        }
        enquiry_hotline {
          country_code
          reminder
          telephone
        }
        meta_title
        focus_keyword
        meta_keywords
        meta_description
        og_image {
          url
        }
        related_post {
          isBroken
          url
        }
      }
      prismicId
      uid
      type
      first_publication_date
      last_publication_date
    }
    relatedOffers: allPrismicOffer(
      limit: 6,
      filter: {
        prismicId: {
          ne: $id
        }
        data: {
          to: {
            gte: $today
          },
          offer_category: {
            id: {
              eq: $categoryId,
            }
          }
        }
      },
      sort: {
        fields: data___date,
        order: DESC
      }
    ){
      nodes {
        data {
          offer_category {
            document {
              ... on PrismicOfferCategory {
                data {
                  name
                }
                uid
                type
              }
            }
          }
          offer_subcategory {
            document {
              ... on PrismicOfferSubcategory {
                uid
                type
                data {
                  name
                  offer_category {
                    uid
                    type
                  }
                  image {
                    url
                  }
                  image_color
                }
              }
            }
          }
          featured_images {
            image {
              thumbnails {
                thumbnail {
                  fluid(maxWidth: 240) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
              alt
            }
          }
          merchants {
            merchant {
              document {
                ... on PrismicMerchant {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
          banks {
            bank {
              document {
                ... on PrismicBank {
                  uid
                  prismicId
                  data {
                    name
                  }
                }
              }
            }
          }
          credit_cards {
            credit_card {
              document {
                ... on PrismicCreditCard {
                  uid
                  data {
                    name
                    bank {
                      id
                    }
                    card_image {
                      alt
                      url
                      dimensions {
                        width
                        height
                      }
                      fixed(height: 42){
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          short_description {
            text
          }
          tagged {
            tag {
              uid
              type
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          title
          to(formatString: "LL", locale: "zh-tw")
        }
        prismicId
        uid
        type
      }
    }
    allMerchantBranches: allPrismicMerchantBranch(
      filter: {
        data: {
          merchant: {
            id: {
              in: $merchantIds
            }
          }
        }
      },
      sort: {
        fields: data___name,
        order: ASC
      }
    ){
      nodes {
        uid
        prismicId
        data {
          address1
          address2
          name
          neighborhood {
            document {
              ... on PrismicNeighborhood {
                uid
                prismicId
                data {
                  name
                  district {
                    document {
                      ... on PrismicDistrict {
                        data {
                          name
                        }
                        prismicId
                        uid
                      }
                    }
                  }
                  region {
                    document {
                      ... on PrismicRegion {
                        uid
                        prismicId
                      }
                    }
                  }
                }
              }
            }
          }
          telephones {
            country_code
            reminder
            telephone
          }
          merchant {
            document {
              ... on PrismicMerchant {
                uid
                prismicId
              }
            }
          }
        }
      }
    }
  }
`

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import useResizeObserver from 'use-resize-observer/polyfilled'
import { stripUnit } from 'polished'
import { useTheme } from '@material-ui/core/styles';
import useResponsive from '@hooks/use-responsive'
import Box from '@lib/ui/core/Box'
import Link from '@lib/ui/core/Link'
import Typography from '@lib/ui/core/Typography'
import Content from './Content'

const toPx = (sizeWithUnit, base) => (stripUnit(sizeWithUnit) * base)

const CollapseContent = ({ maxLine = 6, variant = "body1", ...rest }) => {
  const [collapsible, setCollapsible] = useState(false)
  const [collapsed, setCollapse] = useState(true)
  const theme = useTheme()
  const r = useResponsive()
  const toggle = () => setCollapse(!collapsed)

  const lineHeight = theme ? theme.typography[variant].lineHeight : 1
  const fontSize = r({
    xs: toPx(theme.typography[variant].fontSize, theme.typography.htmlFontSize),
    sm: toPx(theme.typography[variant][theme.breakpoints.up("sm")].fontSize, theme.typography.htmlFontSize),
  })

  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      if (height > maxLine * lineHeight * fontSize) {
        setCollapsible(true)
      } else {
        setCollapsible(false)
      }
    },
  })

  return (
    <Box>
      <Box mb={collapsible ? 2.5 : 0}>
        <Box>
          <Typography component={Box} variant={variant} maxHeight={collapsible && collapsed ? `calc(${maxLine}em * ${lineHeight})` : 0} overflow={collapsible && collapsed ? "hidden" : "none"}>
            <Content ref={ref} variant={variant} {...rest} />
          </Typography>
        </Box>
      </Box>
      { collapsible && (
        <Box textAlign="center" style={{ "overflow-anchor": "none" }}>
          <Link component="button" underline="always" onClick={toggle}>{collapsed ? `顯示全部` : `顯示較少`}</Link>
        </Box>
      )}
    </Box>
  )
}

export default CollapseContent
